import { defineStore } from "pinia";
import { ref, computed } from "vue";

export const useQuizStore = defineStore("quiz", () => {
  const _currentQuiz = ref([]);
  const _currentQuestionIndex = ref(0);
  const _quizState = ref("ready");

  const _score = ref(0);
  const _maxScore = ref(0);
  const _user = ref(null);

  const _parentId = ref(null);
  const _parent = ref(null);
  const _parentTitle = ref(null);

  const currentQuiz = computed(() => _currentQuiz.value);
  const currentQuestion = computed(() => {
    return _currentQuiz.value[_currentQuestionIndex.value];
  });
  const currentQuestionIndex = computed(() => _currentQuestionIndex.value);
  const quizState = computed(() => _quizState.value);

  const score = computed(() => _score.value);
  const maxScore = computed(() => _maxScore.value);
  const user = computed(() => _user.value);

  const parentId = computed(() => _parentId.value);
  const parent = computed(() => _parent.value);
  const parentTitle = computed(() => _parentTitle.value);

  function setCurrentQuiz(quiz) {
    _currentQuiz.value = quiz;
  }

  function nextQuestion(answer) {
    if (_currentQuestionIndex.value >= _currentQuiz.value.length - 1) {
      _currentQuiz.value[_currentQuestionIndex.value].answer = answer;
      _quizState.value = "finished";
      return;
    }
    _currentQuiz.value[_currentQuestionIndex.value].answer = answer;
    _currentQuestionIndex.value++;
  }

  function setScore(score) {
    _score.value = score;
  }

  function setMaxScore(maxScore) {
    _maxScore.value = maxScore;
  }

  function setUser(user) {
    _user.value = user;
  }

  function setParentId(id) {
    _parentId.value = id;
  }

  function setParent(parent) {
    _parent.value = parent;
  }

  function setParentTitle(title) {
    _parentTitle.value = title;
  }

  function restart() {
    _currentQuestionIndex.value = 0;
    _quizState.value = "ready";
    _score.value = 0;
    _maxScore.value = 0;
    _user.value = null;

    _parentId.value = null;
    _parent.value = null;
    _parentTitle.value = null;
  }

  return {
    currentQuiz,
    currentQuestion,
    currentQuestionIndex,
    quizState,
    score,
    maxScore,
    user,

    parentId,
    parent,
    parentTitle,

    setCurrentQuiz,
    nextQuestion,
    setScore,
    setMaxScore,
    setUser,

    setParentId,
    setParent,
    setParentTitle,

    restart,
  };
});
